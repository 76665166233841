@font-face {
  font-family: 'Inter';
  src: local('Inter'), url('./assets/fonts/Inter-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url('./assets/fonts/Inter-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Franklin Gothic';
  src: url('./assets/fonts/Franklin\ Gothic\ Heavy\ Regular.ttf');
}

@font-face {
  font-family: 'ITCFranklinGothicStd';
  src: url('./assets/fonts/ITCFranklinGothicStd-Demi.otf');
}

/* @font-face {
  font-family: 'ITCFranklinGothicStdDmCd';
  src: local('ITCFranklinGothicStd-DmCd'), url('./assets/fonts/ITCFranklinGothicStd-DmCd.otf') format('open');
}  */

html, body {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: 'Inter', 'Franklin Gothic', 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #666;
  font-size: 1rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}
